import * as React from 'react';
import { useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { down, between } from 'styled-breakpoints';
import Image from 'gatsby-image';
import AnswerFeedback from './AnswerFeedback';
import Complete from './Complete';

type QuizProps = {
  questions: Questions[];
  minimumCorrectAnswers?: number;
  images?: any[];
  finalScreen: FinalScreen[];
};

type Questions = {
  question: string;
  answers: string[];
  correct: number;
  background: string;
  response: string[];
};

type FinalScreen = {
  text: string;
};

const imageAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(0);
  }
`;

const QuizSection = styled.section<{ background?: string }>`
  background-color: ${(props) =>
    props.background ? props.background : props.theme.global.color.green};
`;

const QuizWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 125px);
  margin: 0 auto;
  max-width: 1575px;

  ${down('xlmax')} {
    min-height: calc(100vh - 100px);
  }

  ${down('lgmax')} {
    flex-wrap: wrap;
  }

  ${down('mdmax')} {
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 150px);
    height: auto;
    padding: 50px 0;
    margin: 0 auto;
    min-height: calc(100vh - 100px);
    justify-content: flex-start;
    padding: 0;
  }

  ${between('xs', 'smmax')} {
    width: calc(100% - 50px);
  }

  ${down('xsmax')} {
    width: calc(100% - 2rem);
  }
`;

const ImageWrapper = styled.div`
  flex: 0 0 35%;
  max-width: 35%;
  width: 100%;
  margin-top: auto;
  margin-left: 75px;

  ${between('lg', 'xlmax')} {
    flex: 0 0 35%;
    max-width: 35%;
    margin-left: 25px;
  }

  ${down('lgmax')} {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    order: 3;
    margin-left: 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 500px;

  ${down('lgmax')} {
    margin: 0 auto;
  }

  ${down('mdmax')} {
    max-width: 350px;
  }
`;

const AnswerWrapper = styled.div`
  flex: 0 0 35%;
  max-width: 35%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: ${(props) => props.theme.global.color.white};
  margin-right: 75px;

  h1 {
    font-size: 1.75rem;
    margin-top: 0;
    text-align: center;
  }

  ${between('xl', 'xxlmax')} {
    flex: 0 0 40%;
    max-width: 40%;

    h1 {
      font-size: 1.625rem;
    }
  }

  ${between('lg', 'xlmax')} {
    flex: 0 0 45%;
    max-width: 45%;
    margin-left: 0;
    padding: 0;

    h1 {
      font-size: 1.325rem;
    }
  }

  ${between('md', 'lgmax')} {
    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 75px;
    padding: 50px 0;

    h1 {
      font-size: 1.525rem;
    }
  }

  ${down('mdmax')} {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    padding-top: 0;
    margin-right: 0;
    padding-bottom: 50px;
  }

  ${between('sm', 'mdmax')} {
    h1 {
      font-size: 1.425rem;
    }
  }

  ${down('smmax')} {
    h1 {
      font-size: 1.375rem;
    }
  }

  @media (min-width: 1600px) and (max-height: 875px) {
    padding: 50px 0;
  }

  @media (max-width: 1599.98px) and (min-width: 992px) and (max-height: 925px) {
    padding: 50px 0;
  }

  @media (max-width: 1119.98px) and (min-width: 992px) and (max-height: 850px) {
    padding: 50px 0;
  }

  @media (max-width: 991.98px) and (min-width: 768px) and (max-height: 800px) {
    padding: 50px 0;
  }
`;

const Answer = styled.div<{ color: string }>`
  padding: 50px 25px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.global.color.white};
  cursor: pointer;

  &:nth-of-type(2) {
    margin-top: 25px;
  }

  &:hover {
    background-color: ${(props) => props.theme.global.color.white};
    color: ${(props) => props.color};
  }
`;

const AnswerText = styled.p`
  font-size: 1.5rem;
  font-weight: 700;

  ${between('lg', 'xlmax')} {
    font-size: 1.25rem;
  }

  ${between('md', 'lgmax')} {
    font-size: 1.375rem;
  }

  ${between('sm', 'mdmax')} {
    font-size: 1.35rem;
  }

  ${down('smmax')} {
    font-size: 1.25rem;
  }
`;

const Indicator = styled.ul<{ current: number; lenght?: number }>`
  list-style-type: none;
  font-weight: 600;
  font-family: 'Teko', sans-serif;
  color: ${(props) => props.theme.global.color.white};
  margin-right: 50px;
  padding: 0;
  text-align: right;
  order: 1;

  li {
    font-size: 1.5rem;
    cursor: pointer;

    &:nth-of-type(${(props) => props.current}) {
      position: relative;
      font-size: 2.5rem;

      ${down('mdmax')} {
        font-size: 2rem;
      }

      &:before {
        left: -25px;

        ${down('mdmax')} {
          left: 50%;
          bottom: 25%;
          transform: translate(-50%, 100%);
        }
      }

      &:after {
        content: '';
        display: block;
        height: 1px;
        width: 150%;
        background-color: ${(props) => props.theme.global.color.white};
        position: absolute;
        bottom: 15%;
        right: 0;

        ${down('mdmax')} {
          height: 100%;
          width: 1px;
          bottom: 0;
          right: ${(props) =>
            props.lenght! / 2 > props.current ? '-50%' : null};
          left: ${(props) =>
            props.lenght! / 2 < props.current ? '-50%' : null};
        }
      }
    }
  }

  ${between('md', 'lgmax')} {
    margin-top: 50px;
    margin-left: auto;
  }

  ${down('mdmax')} {
    order: 1;
    text-align: center;
    margin-right: 0;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;

    li {
      margin-left: 32px;
      margin-bottom: 1.5rem;
      line-height: 32px;
      font-size: 1.5rem;

      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
`;

const CompletedQuestionInList = styled.li`
  position: relative;
  text-decoration: line-through;
`;

const Quiz: React.FC<QuizProps> = ({
  questions,
  minimumCorrectAnswers,
  images,
  finalScreen,
}) => {
  const test = false;
  const questionLength: number = questions.length - 1;
  const minCorrectAnswers = minimumCorrectAnswers || 5;

  const completedAnswers = useRef<any[] | null>([...Array(questionLength + 1)]);
  const nextImage = useRef<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isAnswered, setIsAnswered] = useState([...Array(questionLength + 1)]);
  const [fScreen, setFScreen] = useState(0);

  const imagesToArray =
    Object.values(images!)[0] !== undefined
      ? Object.values(images!)[0][nextImage.current!]
      : undefined;

  const handleImage = () => {
    nextImage.current = nextImage.current === 2 ? 0 : nextImage.current + 1;
  };

  const handleFinalScreen = () => {
    let correctCount = 0;

    completedAnswers.current?.forEach((answer, idx) => {
      if (answer === questions[idx].correct) {
        correctCount++;
      }
    });

    return correctCount >= minCorrectAnswers ? (
      <Complete
        title={questions[currentQuestion].background}
        content={finalScreen[0].text}
      />
    ) : (
      <Complete
        title={questions[currentQuestion].background}
        content={finalScreen[1].text}
      />
    );
  };

  const updateItemInArray = (i) => {
    setIsAnswered(
      isAnswered.map((item, idx) => {
        return i === idx ? true : item;
      })
    );
  };

  const handleClick = (idx: number, correct: number) => {
    let counting = 0;
    completedAnswers.current![currentQuestion] = idx;

    if (completedAnswers.current![currentQuestion] !== undefined) {
      updateItemInArray(currentQuestion);
    }
  };

  const nextSlide = () => {
    let counting = 0;

    const uncompletedQuestion:
      | number
      | undefined = completedAnswers.current?.indexOf(undefined);

    handleImage();

    if (questions.length > currentQuestion) {
      setCurrentQuestion(uncompletedQuestion!);
    } else {
      uncompletedQuestion !== -1
        ? setCurrentQuestion(uncompletedQuestion!)
        : null;
    }

    completedAnswers.current?.forEach((x, idx) => {
      if (x === undefined) {
        counting++;
      }
    });

    counting === 1 ? setFScreen(1) : null;
  };

  return (
    <QuizSection background={questions[currentQuestion].background}>
      <QuizWrapper>
        {completedAnswers.current?.every(
          (x) => typeof x === 'number' && fScreen === 2
        ) ? (
          handleFinalScreen()
        ) : (
          <>
            <ImageWrapper>
              <ImageContainer>
                {imagesToArray !== undefined ? (
                  <Image fluid={imagesToArray.childImageSharp.fluid} />
                ) : null}
              </ImageContainer>
            </ImageWrapper>
            <Indicator
              current={currentQuestion + 1}
              lenght={questionLength + 1}
            >
              {questions.map((question, idx) => {
                return completedAnswers.current![idx] === undefined ? (
                  <li key={idx} onClick={() => setCurrentQuestion(idx)}>
                    {idx + 1 >= 10 ? idx + 1 : `0${idx + 1}`}
                  </li>
                ) : (
                  <CompletedQuestionInList
                    key={idx}
                    onClick={() => setCurrentQuestion(idx)}
                  >
                    {idx + 1 >= 10 ? idx + 1 : `0${idx + 1}`}
                  </CompletedQuestionInList>
                );
              })}
            </Indicator>
            {isAnswered[currentQuestion] ? (
              <AnswerFeedback
                correct={
                  completedAnswers.current![currentQuestion] ===
                  questions[currentQuestion].correct
                    ? 1
                    : 0
                }
                feedback={
                  questions[currentQuestion].response[
                    completedAnswers.current![currentQuestion] ===
                    questions[currentQuestion].correct
                      ? 0
                      : 1
                  ]
                }
                color={questions[currentQuestion].background}
                nextSlide={nextSlide}
                fScreen={fScreen}
                setFScreen={setFScreen}
              />
            ) : (
              <AnswerWrapper>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: questions[currentQuestion].question,
                  }}
                />
                {questions[currentQuestion].answers.map((answer, idx) => (
                  <Answer
                    color={questions[currentQuestion].background}
                    key={`_.${idx}`}
                    onClick={() =>
                      handleClick(idx, questions[currentQuestion].correct)
                    }
                  >
                    <AnswerText dangerouslySetInnerHTML={{ __html: answer }} />
                  </Answer>
                ))}
              </AnswerWrapper>
            )}
          </>
        )}
      </QuizWrapper>
    </QuizSection>
  );
};

export default Quiz;

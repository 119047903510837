import * as React from 'react';
import Layout from '../components/_global/Layout';
import Quiz from '../components/quiz/Quiz';
import { graphql } from 'gatsby';

const DogQuiz = ({ data }) => {
  const dogQuestions = [
    {
      question: 'Chodíš na party? Jak často?',
      answers: ['Ne víc než jednou týdně.', 'Skoro denně.'],
      response: [
        'Skvěle! Vypadá to, že bychom spolu mohli vycházet dobře. Jen měj na paměti, že když budeš pryč moc dlouho, můžu ti na podlaze nechat překvapení.',
        'Vážně? I já si rád vyrazím někam zavrtět ocasem, ale není nic horšího než být pořád sám doma. Pak jsem ve stresu nebo se začnu nudit a mohou to odnést tvé věci. Třeba boty nebo nábytek.',
      ],
      correct: 0,
      background: '#ffb500',
    },
    {
      question: 'Máš hodně koníčků?',
      answers: ['Zatím ani ne.', 'Ano, mám jich spoustu.'],
      response: [
        'To je dobrá zpráva! Vypadáš jako někdo, kdo se mi bude plně věnovat. Víš, že existují i psí sporty? Můžeme je zkusit spolu!',
        'Dobře pro tebe! Ale co já? Ne všech aktivit se můžu účastnit s tebou. Venkovní sporty, jako běh nebo plavání v přírodě, si můžu užívat s tebou, až budu větší. Ale je spousta míst, kam mě ani nepustí. Uvědomuješ si to?',
      ],
      correct: 0,
      background: '#94c11e',
    },
    {
      question: 'Jsi ranní ptáče?',
      answers: [
        'Vůbec! Nevstávám dřív než před obědem.',
        'Jó! Rána jsou moje nejoblíbenější část dne!',
      ],
      response: [
        'Moje taky! Není nad to, ulevit si po ránu na oroseném trávníku, viď?',
        'To se se mnou změní, neboj. Venčení ještě před snídaní si určitě zamiluješ. A i kdyby ne, stejně se mu nevyhneš. Ani když ti nebude dobře… a ani s kocovinkou. Když zaspíš, můžeš u dveří najít malé překvapení.',
      ],
      correct: 1,
      background: '#ffb500',
    },
    {
      question: 'Myslíš, že pes je vhodný dárek pro dítě?',
      answers: ['Ani náhodou!', 'To zní jako dobrý nápad!'],
      response: [
        'Uff, ještě že tak. Vždyť mě nejde ani jak zabalit!',
        'Ach jo… Každý rok končí v útulcích stovky mých kámošů, protože někomu příliš pozdě došlo, že se o mě nedokáže postarat. A nejsmutnější je, že většinou hned po Vánocích nebo prázdninách.',
      ],
      correct: 0,
      background: '#94c11e',
    },
    {
      question: 'Podle čeho se rozhoduješ při výběru rasy?',
      answers: [
        'Podle toho, které plemeno se mi nejvíc líbí a jehož štěně seženu někde poblíž v okolí.',
        'Zjistím si informace o různých plemenech.',
      ],
      response: [
        'To dává smysl. Je dobré nejprve zvážit své možnosti a také to, co od svého nového parťáka čekáš. Přece jen spolu strávíme celý můj život.',
        'Ajajaj. Tohle by nemuselo skončit dobře. Některá plemena vyžadují specifický přístup a nejsou vhodná pro „začátečníky“. Může se stát, že budeme mít každý jiný životní styl a aktivitu. Ne všichni psí kamarádi jsou vhodní do bytu, jiní potřebují intenzivní péči o srst nebo kůži. Počítáš s tím?',
      ],
      correct: 1,
      background: '#ffb500',
    },
    {
      question: 'Vyděláváš dost na to, aby mohlo jít každý měsíc něco stranou?',
      answers: [
        'Většinou žiju od výplaty k výplatě.',
        'Jasně, něco ušetřit pro mě není problém.',
      ],
      response: [
        'Pak tě asi nějaký ten výdaj navíc nepřekvapí. Mít psa, to neznamená jen kupovat pro něj krmivo. I když je pravda, že plná miska mi vždycky udělá radost.',
        'Možná si říkáš, že jeden chlupatý hladový krk navíc zvládneš, ale život se mnou není jen o nákupu psího krmiva. Očkování, pelíšek, vodítko, hračky, které se většinou rychle zničí, psí hotely, když nenajdeš nikoho, kdo by mě pohlídal… A to nemluvím o tom, kolik stojí léčba, kdybych onemocněl nebo se mi něco stalo. A co když něco zničím? Nebo někomu jinému? I to se může stát.',
      ],
      correct: 1,
      background: '#94c11e',
    },
    {
      question: 'Víš, na koho se nejlépe obrátit při pořizování psa?',
      answers: [
        'Dám na svou intuici, projdu inzeráty na internetu a vyberu prodejce s nejlepší cenou.',
        'Podívám se na specializované chovatelské weby a pak se obrátím na prověřené chovatele.',
      ],
      response: [
        'Haf! Kdyby byli všichni takhle rozumní, nemuselo by existovat tolik psích útulků.',
        'Vážně si myslíš, že cena by měla být tím hlavním? Internet je plný inzerátů množíren, kterým jde jen o zisk a psi v jejich držení trpí ve špatných podmínkách někdy i mnoho let. Bylo by lépe ujmout se některého z mých brášků z psího útulku nebo zajít do prověřené chovatelské stanice. Navíc peníze, za které mě koupíš, jsou jen zlomkem toho, kolik do mě za můj život budeš muset investovat.',
      ],
      correct: 1,
      background: '#ffb500',
    },
    {
      question:
        'Máš v sobě dostatek kázně dělat každý den ve stejnou dobu stejné věci?',
      answers: [
        'Ano, preferuju systém, vše si plánuji předem.',
        'Nechci se podřizovat plánům, mám radši spíše život trochu chaotický, bez pevného řádu.',
      ],
      response: [
        'Paráda, pochopíš dobře moje potřeby. I já potřebuji ke štěstí řád.',
        'Je mi líto, ale nevím, jestli pochopíš můj charakter. Mám rozum jako tříleté dítě. A to potřebuje řád stejně jako já.',
      ],
      correct: 0,
      background: '#94c11e',
    },
    {
      question: 'Rozumíš tomu, že mě nejvíc motivuje pamlsek a pochvala?',
      answers: [
        'Ne, výcvik je jako na vojně – povel, jeho splnění, nic víc.',
        'Jo, každý přece ví, co je to pozitivní motivace.',
      ],
      response: [
        'Tak to už se těším na společný výcvik a výchovu. I já umím člověka pozitivně motivovat.',
        'No, tak to si snad raději pořiď plyšáka… Taky nechceš chodit do práce, kde by na tebe jen křičeli povely. Pamlsek je pro mě to, co je pro tebe výplata.',
      ],
      correct: 1,
      background: '#ffb500',
    },
    {
      question:
        'Uvědomuješ si, že nikdy nebudu umět nic jiného, než co mě naučíš? Že ti někdy přijdu nechápavý, paličatý, neučenlivý?',
      answers: [
        'Jo, základy výchovy psů pro mě nejsou nic nového, mám v dosahu dost dobrý cvičák.',
        'Pes vždycky musí poslechnout, od toho je přece výcvik, aby si pes uvědomil, že páníčkův rozkaz je zákon.',
      ],
      response: [
        'Pěkné, to bude moc fajn.',
        'Víš co? Škubni si, já k tobě zkrátka nechci.',
      ],
      correct: 0,
      background: '#94c11e',
    },
    {
      question: 'Cestuješ často?',
      answers: ['Ani ne.', 'Jasně, cestuju pořád!'],
      response: [
        'Ani já ne. I když jízdu v autě si občas užívám.',
        'Cestování se mnou nebude tak jednoduché, obzvlášť za hranice. Nejen hotely, ale i každý stát má svá pravidla a ne všude mě budeš moci vzít s sebou. Máš jistotu, že máš v okolí někoho, kdo mě pohlídá? I tak bych ale neměl u cizích lidí zůstávat moc často. Je to pro mě stresující.',
      ],
      correct: 0,
      background: '#ffb500',
    },
  ];

  const finalTexts = [
    {
      text:
        'Vypadá to, že jsi „psí“ člověk a pořízení čtyřnohého parťáka nebereš na lehkou váhu. Už přemýšlíš o tom, jaké plemeno chceš? Každý z nás má jinou povahu a také jiné potřeby, pokud ještě nevíš, zkus třeba zajít za odborníky v chovné stanici nebo v některém z útulků. Brzy na viděnou v parku!',
    },
    {
      text:
        'Vidím, že se snažíš, ale nechci, abych měl já nebo kdokoliv z mých brášků zlomené srdce. Možná by pro tebe bylo lepší navštívit některý z útulků a popovídat si s odborníky o tom, jak se na péči o čtyřnohého kamaráda připravit. Získáš tak lepší obrázek, a navíc si i můžeš vyzkoušet být páníčkem „nanečisto“, když vezmeš některého z místních psů na procházku.',
    },
  ];

  return (
    <Layout>
      <Quiz
        questions={dogQuestions}
        images={data.allFile}
        minimumCorrectAnswers={6}
        finalScreen={finalTexts}
      />
    </Layout>
  );
};

export const query = graphql`
  query imagesDogQuiz {
    allFile(filter: { dir: { regex: "/(kviz-psi)/" } }) {
      nodes {
        childImageSharp {
          fixed {
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 750) {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
            base64
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default DogQuiz;

import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { down, between } from 'styled-breakpoints';

export const Animation = keyframes`
    0%{
        opacity: 0;
        transform: translateY(100%);
    }

    100%{
        opacity: 1;
        transform: translateY(0);
    }
`;

const FeedbackWrapper = styled.div`
  flex: 0 0 35%;
  max-width: 35%;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  text-align: center;
  animation: 0.75s ${Animation} ease forwards;
  margin: 50px 75px 50px 0;

  p {
    color: ${(props) => props.theme.global.color.grey};
    padding: 0 50px;
    margin-top: 50px;
  }

  ${between('xl', 'xxlmax')} {
    flex: 0 0 40%;
    max-width: 40%;
  }

  ${between('lg', 'xlmax')} {
    flex: 0 0 45%;
    max-width: 45%;
  }

  ${between('md', 'lgmax')} {
    flex: 0 0 60%;
    max-width: 60%;
    margin-left: 75px;
  }

  ${down('mdmax')} {
    margin-right: 0;
    flex: unset;
    max-width: 100%;
    order: 2;
    margin-bottom: 50px;
    margin-top: 0;
  }

  ${down('smmax')} {
    max-width: calc(100% - 50px);

    p {
      padding: 0 40px;
    }
  }

  @media (max-width: 420px) {
    max-width: calc(100% - 2rem);

    p {
      padding: 0 25px;
    }
  }
`;

export const StyledTitle = styled.h1<{ color: string }>`
  text-transform: uppercase;
  color: ${(props) => props.color};
  margin-top: 50px;
  margin-bottom: 2rem;
`;

const Next = styled.div`
  background-color: #95c11f;
  padding: 12px 50px;
  border-radius: 13px;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.global.color.white};
  margin: 50px 0 75px;
  display: inline-block;
  cursor: pointer;
`;

const AnswerFeedback: React.FC<{
  correct: number;
  feedback: string;
  color: string;
  nextSlide?: () => void;
  fScreen: number;
  setFScreen: (fScreen: number) => void;
}> = ({ correct, feedback, color, nextSlide, fScreen, setFScreen }) => {
  return (
    <FeedbackWrapper>
      <p>{feedback}</p>
      {fScreen === 0 ? (
        <Next onClick={nextSlide}>Další otázka</Next>
      ) : (
        <Next onClick={() => setFScreen(2)}>Vyhodnotit</Next>
      )}
    </FeedbackWrapper>
  );
};

export default AnswerFeedback;

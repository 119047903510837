import * as React from 'react';
import styled from 'styled-components';
import { down, between } from 'styled-breakpoints';
import { Location } from '@reach/router';
import { Link } from 'gatsby';
import { Animation, StyledTitle } from './AnswerFeedback';
import { Button } from '../shared/Button';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.global.color.white};
  margin: 50px auto;
  text-align: center;
  border-radius: 6px;
  padding: 0 150px 75px;
  max-width: 675px;
  color: ${(props) => props.theme.global.color.grey};
  animation: 0.75s ${Animation} ease forwards;

  p {
    margin-bottom: 0;
  }

  ${between('lg', 'xlmax')} {
    max-width: 575px;
  }

  ${between('md', 'lgmax')} {
    padding: 0 25px 75px;

    p {
      padding: 0 35px;
    }
  }

  ${down('mdmax')} {
    max-width: 100%;
    margin: 75px 0;
    padding: 0 0 75px;

    p {
      padding: 0 50px;
    }
  }

  ${between('xs', 'smmax')} {
    max-width: calc(100% - 50px);

    p {
      padding: 0 40px;
    }
  }

  @media (max-width: 420px) {
    max-width: 100%;

    p {
      padding: 0 25px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonExtend = styled.a`
  min-width: 175px;
  margin-top: 15px;
  display: block;

  &:nth-of-type(1) {
    margin-top: 50px;
  }
`;

const CrossBack = styled(Link)`
  width: 27.5px;
  height: 27.5px;
  position: absolute;
  top: 25px;
  right: 42.5px;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 27.5px;
    height: 3px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.global.color.black};
    transition: background-color 0s linear 0.13s;
    display: block;
  }

  &:after {
    transform: translate3d(0, 10px, 0) rotate(-45deg);
  }

  &:before {
    transform: translate3d(0, 10px, 0) rotate(45deg);
  }
`;

const Complete: React.FC<{ title: string; content: string }> = (props) => {
  return (
    <Wrapper>
      <CrossBack to={'/'} />
      <StyledTitle color={props.title}>Hezky pěkně!</StyledTitle>
      <p>{props.content}</p>
      <ButtonWrapper>
        <ButtonWrapperInner>
          <Location>
            {({ location }) => {
              return location.pathname.includes('kocici-kviz') ? (
                <ButtonExtend
                  as={Button}
                  href={'https://krmivo-brit.cz/cs/katalog-plemen/kocky'}
                  target={'_blank'}
                >
                  KATALOG PLEMEN KOČEK
                </ButtonExtend>
              ) : (
                <>
                  <ButtonExtend
                    as={Button}
                    href={'https://krmivo-brit.cz/cs/breed-catalog'}
                    target={'_blank'}
                  >
                    KATALOG PLEMEN PSŮ
                  </ButtonExtend>
                  <ButtonExtend
                    as={Button}
                    href={'https://www.cmku.cz/cz/chovatelske-stanice-123'}
                    target={'_blank'}
                  >
                    NAJDI CHOVNOU STANICI
                  </ButtonExtend>
                  <ButtonExtend
                    as={Button}
                    href={'https://www.clickandfeed.cz/utulky/'}
                    target={'_blank'}
                  >
                    NAJDI ÚTULEK{' '}
                  </ButtonExtend>
                </>
              );
            }}
          </Location>
        </ButtonWrapperInner>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Complete;
